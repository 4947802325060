import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import io from 'socket.io-client';
import {
    Box,
    Divider,
    Typography,
    InputBase,
    useTheme,
    Button,
    IconButton,
    useMediaQuery,
  } from "@mui/material";
  import{
  Close
} from "@mui/icons-material";
import { useSelector } from 'react-redux';
import InputEmoji from 'react-input-emoji'
import FlexBetween from 'components/FlexBetween';
import { ChatContext } from 'context/ChatContext';
import { useFetchRecipient } from 'hooks/userrecipients';

import { setChatMessages, setMessage } from 'state';
import moment from 'moment';


// const socket = io(process.env.REACT_APP_API_BASE_URL)

function ChatApp({ userId, isChat, setIsChat }) {
  // const messages =useSelector((state)=> state?.chatMessages)
 
  const token = useSelector((state) => state.token);
  const scroll =useRef()
  const users = useSelector((state)=>state?.users )
  const [text, setText] =useState("")

  const {messages, sendTextMessage, currentChat, user, setTextMessage} = useContext(ChatContext)
  const recipientUser = useFetchRecipient(currentChat, user?._id)

  console.log(recipientUser, 'reci')
 
  const { palette } = useTheme();


  useEffect(()=>{
    scroll.current?.scrollIntoView({behavior:"smooth"})
  }, [messages])

  // useEffect(() => {
  //     getCurrentMessage()
  //   // return () => {
  //   //   socket.off('message');
  //   // };
  // }, []);

  // const sendMessage = () => {
  //   if (input.trim() !== '') {
  //     socket.emit('sendMessage', { senderId: userId, receiverId: receiverId, message: input });
  //     setInput('');
  //   }
  // };

  // console.log(messages, 'msg')
  // console.log(currentChat, 'chat')


  const getRecipient= ()=>{
    const recipientId = currentChat?.members?.find((id)=> id !== userId)
    const user = users && users.find((user)=> user._id === recipientId)
    return user
  }
  
   

    const getSender = (senderId)=>{
      const user = users.find((user)=> user._id === senderId)
      return user;
    }
    
    

// console.log(currentChat, 'current chat')



  return (
    <div style={{
      height: '100%'
  }} >
      {/* <div> */}

      <div style={{
        width:'100%',
        height:'40px',
        top:0,
        display:'flex',
        justifyContent:"space-between",
        alignItems:'center',
        background:"#e0e0f4"
      }}>
         
        <Typography  style={{fontSize:"16px", paddingLeft:"10px"}}>
          {`Yor are chatting with ${getRecipient()?.firstName} - ${getRecipient()?.lastName}`}
           </Typography>
           <IconButton
              onClick={() => setIsChat(false)}
            >
              <Close sx={{ color: "red" }} />
            </IconButton>
      </div>
    <div style={{height:'80%',   overflow:"scroll"}}>
        {
        messages && messages.map((message, index) => (
        <div key={index} style={{display: 'flex',  justifyContent:`${message?.senderId === userId?'flex-end':'flex-start'}`}}>
         
            {message?.senderId === userId ? 
            
            <div style={{color:`${message?.senderId === userId?'pink' : 'green'}`, background:`${message?.senderId === userId?'green':'blue'}` , padding:'5px'}}>
              You:<span style={{color:"#fff"}}> {message?.text}</span> </div> :

<div style={{color:'green', background:'#eoeoeo' , padding:'5px'}}>
{`${getRecipient()?.firstName} - ${getRecipient()?.lastName}`}:<span style={{color:"#888888"}}> {message?.text}</span>
<div>{moment(message?.createdAt).calendar()} </div>
 </div>
    
           
                        
              }
           
          </div>
        
        ))}
</div>
    <FlexBetween>
      {/* <InputEmoji
        type="text"
        style={{
         width:'40%',
         marginRight:1,
         padding:5 , 
        }}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      /> */}

<input
        type="text"
        style={{
         width:'80%',
         marginRight:1,
         padding:5 , 
        }}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    
      <Button
            disabled={!text}
            variant='contained'
            onClick={()=> {
              sendTextMessage(text, userId, currentChat?._id, setText )
              // setInput("")
            }}
            sx={{
              backgroundColor: palette.primary.btn,
              color: "#ffffff",
              borderRadius: "6px",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: palette.primary.hBtn,
                cursor: "pointer",
              },
            }}
          >
            Send
          </Button>
          {/* </div> */}
          </FlexBetween>
    </div>
  );
}

export default ChatApp;