import { useState, useContext } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Search,
  Message,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Home,
  Menu,
  Close,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { ChatContext } from "context/ChatContext";


const Navbar = ({isChat, setIsChat, joinChatHandle, receiverId}) => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const {notifications, isOpen, setIsOpen} = useContext(ChatContext)


  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.primary.btn;
  // const primaryLight = theme.palette.primary.hBtn;
  const alt = theme.palette.primary.btn;

  const fullName = `${user?.firstName} ${user?.lastName}`;

  return (
    <FlexBetween padding='1rem 6%' backgroundColor={alt}>
      <FlexBetween gap='1.75rem'>
        <img
          width='50px'
          height='50px'
          alt='post'
          style={{ borderRadius: "100%", cursor: "pointer" }}
          src='../assets/logo.png'
          onClick={() => navigate("/home")}
        />
        {isNonMobileScreens && (
          <FlexBetween
            backgroundColor={neutralLight}
            borderRadius='6px'
            gap='3rem'
            padding='0.1rem 1.5rem'
          >
            <InputBase placeholder='Search...' />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
        )}
      </FlexBetween>

      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween gap='2rem'>
          <Home
            onClick={() => navigate("/home")}
            style={{ cursor: "pointer" }}
            sx={{ fontSize: "25px", color: "#ffffff" }}
          />
          {
            document?.location?.pathname.indexOf('/profile') !== -1 ?
            
            <Message
            style={{ cursor: "pointer" }}
            sx={{ fontSize: "25px", color: "#ffffff" }}
            onClick={() =>{
              setIsChat(!isChat)
              joinChatHandle(user?._id, receiverId)
            }}
          />
            :
            
            null
            
          }
        <Box style={{
          position:'relative'
        }}
      
        >
          <Typography style={{
            position:'absolute',
            top:-3,
            left:10,
            fontWeight:600,
            fontSize:16,
            color:"#fff",
            background:`${notifications?.length>0?"red":'' }`,
            borderRadius:50,
            width:22,
            height:22,
            textAlign:'center'
           
          }}>{notifications?.length > 0? notifications?.length: null}</Typography>
        <Notifications
            style={{ cursor: "pointer" }}
            sx={{ fontSize: "25px", color: "#ffffff" }}
            onClick={()=> setIsOpen(!isOpen)}
          />
          
        </Box>
        
          <Help
            style={{ cursor: "pointer" }}
            sx={{ fontSize: "25px", color: "#ffffff" }}
          />
          <FormControl variant='standard' value={fullName}>
            <Select
              value={fullName}
              sx={{
                backgroundColor: neutralLight,
                width: "150px",
                borderRadius: "0.25rem",
                p: "0.25rem 1rem",
                "& .MuiSvgIcon-root": {
                  pr: "0.25rem",
                  width: "3rem",
                },
                "& .MuiSelect-select:focus": {
                  backgroundColor: neutralLight,
                },
              }}
              input={<InputBase />}
            >
              <MenuItem value={fullName}>
                <Typography>{fullName}</Typography>
              </MenuItem>
              <MenuItem>
                <IconButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={() => dispatch(setMode())}
                >
                  {theme.palette.mode === "dark" ? (
                    <Box
                      display='flex'
                      gap='5px'
                      style={{
                        marginLeft: "-10px",
                        marginTop: "-5px",
                        marginBottom: "-5px",
                      }}
                    >
                      <DarkMode sx={{ fontSize: "25px" }} />
                      <Typography>Light Theme</Typography>
                    </Box>
                  ) : (
                    <Box
                      display='flex'
                      gap='5px'
                      style={{ marginLeft: "-10px" }}
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <LightMode />
                      <Typography>Dark Theme</Typography>
                    </Box>
                  )}
                </IconButton>
              </MenuItem>
              <MenuItem onClick={() => dispatch(setLogout())}>Log Out</MenuItem>
            </Select>
          </FormControl>
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <Menu sx={{ color: "#ffffff" }} />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position='fixed'
          right='0'
          bottom='0'
          height='100%'
          zIndex='10'
          maxWidth='500px'
          minWidth='300px'
          backgroundColor={background}
        >
          {/* CLOSE ICON */}
          <Box display='flex' justifyContent='flex-end' p='1rem'>
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close sx={{ color: "#ffffff" }} />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            gap='3rem'
          >
            <Home
              onClick={() => navigate("/home")}
              style={{ cursor: "pointer" }}
              sx={{ fontSize: "25px", color: "#ffffff" }}
            />
            <Message
              onClick={() =>{
                setIsChat(!isChat)
                joinChatHandle(user?._id, receiverId)
              }}
              style={{ cursor: "pointer" }}
              sx={{ fontSize: "25px", color: "#ffffff" }}
          
            />
              <Box style={{
          position:'relative'
        }}
        >
          
          <Typography style={{
            position:'absolute',
            top:-3,
            left:10,
            fontWeight:600,
            fontSize:16,
            color:"#fff",
            background:`${notifications?.length>0?"red":'' }`,
            borderRadius:50,
            width:22,
            height:22,
            textAlign:'center'
           
          }}>{notifications?.length > 0? notifications?.length: null}</Typography>
            <Notifications
              style={{ cursor: "pointer" }}
              sx={{ fontSize: "25px", color: "#ffffff" }}
              onClick={()=>setIsOpen(!isOpen)}
            />

        </Box>
          
            <Help
              style={{ cursor: "pointer" }}
              sx={{ fontSize: "25px", color: "#ffffff" }}
            />
            <FormControl variant='standard' value={fullName}>
              <Select
                value={fullName}
                sx={{
                  backgroundColor: neutralLight,
                  width: "150px",
                  borderRadius: "0.25rem",
                  p: "0.25rem 1rem",
                  "& .MuiSvgIcon-root": {
                    pr: "0.25rem",
                    width: "3rem",
                  },
                  "& .MuiSelect-select:focus": {
                    backgroundColor: neutralLight,
                  },
                }}
                input={<InputBase />}
              >
                <MenuItem value={fullName}>
                  <Typography>{fullName}</Typography>
                </MenuItem>
                <MenuItem>
                  <IconButton
                    onClick={() => dispatch(setMode())}
                    sx={{ fontSize: "25px" }}
                  >
                    {theme.palette.mode === "dark" ? (
                      <DarkMode sx={{ fontSize: "25px" }} />
                    ) : (
                      <LightMode sx={{ color: dark, fontSize: "25px" }} />
                    )}
                  </IconButton>
                </MenuItem>
                <MenuItem onClick={() => dispatch(setLogout())}>
                  Log Out
                </MenuItem>
              </Select>
            </FormControl>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default Navbar;
