import {
    EditOutlined,
    DeleteOutlined,
    AttachFileOutlined,
    GifBoxOutlined,
    ImageOutlined,
    MicOutlined,
    MoreHorizOutlined,
  } from "@mui/icons-material";
  import moment from 'moment'
  import UserImage from "components/UserImage";

  import {
    ChatBubbleOutlineOutlined,
    FavoriteBorderOutlined,
    FavoriteOutlined,
    ShareOutlined,
  } from "@mui/icons-material";
  import {
    Box,
    Divider,
    Typography,
    InputBase,
    useTheme,
    Button,
    IconButton,
    useMediaQuery,
  } from "@mui/material";
  import FlexBetween from "components/FlexBetween";
  import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
  import {setComments} from "state"


  const CommentComponent = ({comment, patchLike, comments, parentId, likes, handleComment})=>{

    const loggedInUserId = useSelector((state) => state?.user?._id);
 

    const users = useSelector((state)=> state?.users)
    const isLiked = likes? Boolean(likes[loggedInUserId]) : false
    const likeCount = likes ? Object.keys(likes).length : null;
    const [isComments, setIsComments] = useState(false);
    const [mycomment, setMyComment] = useState("")
    const { palette } = useTheme();
    const [expanded, setExpanded] = useState(false);
    const dark = palette.neutral.dark;
  
    const main = palette.neutral.main;



    
    // console.log(postComment, 'postComment')

 

    const toggleExpand = () => {
      setExpanded(!expanded);
    };
    useEffect(()=>{}, [comment])


    if(users && comment){

    
      let replyUser = users && users.find((x)=> x._id == comment.userId)

       return (

       <Box key={parentId} padding={2}>
         
             <FlexBetween gap='1.5rem'>
             {/* {comment.userPicturePath && ( */}
               <UserImage image={comment?.userPicturePath} size="30px"/>
      {/* )} */}
      <Box width={600}>
        <Typography
          
              fontWeight='100'
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }} >
          
{comment?.text}

            </Typography>
            </Box>
            </FlexBetween>   
                  <FlexBetween mt='0.25rem'>
                  <FlexBetween gap='1rem'>
                    <FlexBetween gap='0.3rem'>
                      <IconButton onClick={()=> patchLike(comment._id)}>
                        {isLiked ? (
                          <FavoriteOutlined sx={{ color: "#dc2626" }} />
                        ) : (
                          <FavoriteBorderOutlined sx={{ color: "#000000" }} />
                        )}
                      </IconButton>
                      <Typography sx={{ color: "#000000" }}>{likeCount}</Typography>
                    </FlexBetween>
          
                    <FlexBetween gap='0.3rem'>
                      <IconButton
                        sx={{ color: "#000000" }}
                        onClick={() => setIsComments(!isComments)}
                      >
                        <ChatBubbleOutlineOutlined />
                      </IconButton>
                      <Typography sx={{ color: "#000000" }}>{comments?.length}</Typography>
                    </FlexBetween>
                  </FlexBetween>
          
                  <IconButton>
                    <ShareOutlined sx={{ color: "#000000" }} />
                  </IconButton>
                </FlexBetween>
                <FlexBetween gap='1rem'>
      <Typography color={main} sx={{ mt: "1rem" }} style={{fontSize:"10px"}}>
      comment by: {replyUser?.firstName} on { moment(comment?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
      </Typography>
        </FlexBetween>
<Divider />
          <FlexBetween gap="1.5rem">
        {/* <UserImage image={picturePath} /> */}
        {
          isComments?
        <>
        <InputBase
          placeholder="reply comment..."
          onChange={(e) =>setMyComment(e.target.value)}
          value={mycomment}
          sx={{
            width: "100%",
            backgroundColor: palette.neutral.light,
            borderRadius: "2rem",
            padding: "2px 5px",
          }}
        />
         <Button
          disabled={!mycomment}
          onClick={()=>{ handleComment(mycomment, setMyComment)
          setMyComment("")
          }}
          sx={{
            color: "#fff",
            backgroundColor: palette.primary.btn,
            borderRadius: "6px",
          }}
          style={{
            color:"#ffffff",
            padding:"3px 5px"
          }}
        >
          reply
        </Button>
</>
        :
        null
  }
      </FlexBetween>
                 
            </Box>

       )
}
    }
    
//     else{
      
//     return(
//       <>

//         <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
// {comment}

//             </Typography>
//                   <FlexBetween mt='0.25rem'>
//                   <FlexBetween gap='1rem'>
//                     <FlexBetween gap='0.3rem'>
//                       <IconButton onClick={patchLike}>
//                         {isLiked ? (
//                           <FavoriteOutlined sx={{ color: "#dc2626" }} />
//                         ) : (
//                           <FavoriteBorderOutlined sx={{ color: "#000000" }} />
//                         )}
//                       </IconButton>
//                       <Typography sx={{ color: "#000000" }}>{likeCount}</Typography>
//                     </FlexBetween>
          
//                     <FlexBetween gap='0.3rem'>
//                       <IconButton
//                         sx={{ color: "#000000" }}
//                         onClick={() => setIsComments(!isComments)}
//                       >
//                         <ChatBubbleOutlineOutlined />
//                       </IconButton>
//                       <Typography sx={{ color: "#000000" }}>{comments?.length}</Typography>
//                     </FlexBetween>
//                   </FlexBetween>
          
//                   <IconButton>
//                     <ShareOutlined sx={{ color: "#000000" }} />
//                   </IconButton>
//                 </FlexBetween>
//                 </>
//     )
//     }

  // }



  export default CommentComponent;