import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const useFetchRecipient =(chat, user) =>{
    const [recipientUser, setRecipientUser] = useState(null)
    const token = useSelector((state) => state.token);
    const [error, setError] =useState(null)

    const recipientId = chat?.members.find((id) => id !== user?._id)

    useEffect(()=>{
        const getUser =async()=>{
            if(!recipientId) return;
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/find/${recipientId}`, {
                method: "GET",
                headers: { Authorization: `Bearer ${token}`, "Content-type":"application/json"},
              });

              const data = await response.json()

              if(data.error){
              
                return setError(data)
              }
           

              setRecipientUser(data)
        }

        getUser()
      
    },[])


    return {recipientUser}
}