import { Box } from "@mui/material";
import MyProfile from "../assets/default-profile-image.png"

const UserImage = ({ image, size = "60px" }) => {

  return (
    <Box
      width={size}
      height={size}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
        {
             image?.indexOf('https') !== -1 ?

             <img
             style={{ objectFit: "cover", borderRadius: "50%" }}
             width={size}
             height={size}
             alt='user'
             src={
            
                 image
                 
             
             }
     
             // src={`${process.env.REACT_APP_API_BASE_URL}/assets/${image}`}
           />
             :

             <img
             style={{ objectFit: "cover", borderRadius: "50%" }}
             width={size}
             height={size}
             alt='user'
             src={
            
                 MyProfile
                 
             
             }
     
             // src={`${process.env.REACT_APP_API_BASE_URL}/assets/${image}`}
           />

        }
   
    </Box>
  );
};

export default UserImage;
