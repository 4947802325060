import { Box, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import UserWidget from "scenes/widgets/UserWidget";
import { ChatContext } from "context/ChatContext";
import ChatApp from "scenes/Chat";
import {io} from "socket.io-client"
import {setNewChat,setOnlineUsers,setMessage, setCurrentChat, setChatMessages, setLoading, setNewCha} from "state";



const ProfilePage = () => {
  // const [user, setUser] = useState(null);
  const { userId } = useParams();
  const dispatch = useDispatch()
  const token = useSelector((state) => state.token);
 
  const { _id, picturePath } = useSelector((state) => state.user);
  const onlineUsers = useSelector((state)=> state?.onlineUsers)
  // const currentChat = useSelector((state)=> state.currentChat)
  // const chatMessages = useSelector((state)=> state.chatMessages)
  // const newChat = useSelector((state)=>state.newChat)
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const [socket, setSocket] =useState(null)
  // const [text, setText] = useState("")
  // const  [currentChat, setCurrentChat] = useState(null)
  const {user, createChats, isChat, setIsChat} = useContext(ChatContext)
  // const [newMessage, setNewMessage] = useState(null)

  // const[onlineUsers, setOnlineUsers] = useState(null)



//   const joinChatHandle =useCallback(async()=>{
//    const body = {
//     firstId: _id,
//     secondId:userId
// }
//     const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chats`, {
//       method: "POST",
//       headers: { Authorization: `Bearer ${token}`, "Content-type":"application/json"},
//       body: JSON.stringify(body)
//     });
//     const data = await response.json();
  
//     dispatch(setCurrentChat({currentChat:data}))
//   }, [])


  // const getCurrentMessage = useCallback(async()=>{
  //   // const recipientId = currentChat?.members.find((id)=> id !== _id)
  //  const currentId = currentChat?._id
  //   if(!currentId) return null

  //   const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/messages/${currentId}`, {
  //     method: "GET",
  //     headers: { Authorization: `Bearer ${token}`, "Content-type":"application/json"},
  //   });

  //   if(response.error){
  //     setMessage(response?.error)
  //   }

  //   const data= await response.json()

  //   console.log(data, 'dt')

  //   dispatch(setChatMessages(data))

  // }, [])

  // const handleSendMessage = useCallback(async(text)=>{
  //     if(currentChat && text){
  //       const body = {
  //         chatId:currentChat?._id,
  //         senderId:_id,
  //         text:text
  //       }

  //       const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/messages`, {
  //         method: "POST",
  //         headers: { Authorization: `Bearer ${token}`, "Content-type":"application/json"},
  //         body: JSON.stringify(body)
  //       });
  //       const data = await response.json();
  //         setNewMessage(data)
  //         dispatch(setNewChat(data))
       
  //     }
  // },[])


  // const getUser =useCallback(async () => {
  //   setLoading(true)
  //   const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`, {
  //     method: "GET",
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  //   const data = await response.json();
  //   setUser(data);
  //   setLoading(false)
  // }, [])



  // useEffect(()=>{
  //   const newSocket = io("http://localhost:5000");
  //   setSocket(newSocket)
  //   // getCurrentMessage()
  //   return ()=>{
  //     newSocket.disconnect()
  //   }
  // }, [user])




  // useEffect(()=>{

  //   if(socket == null) return
  //   socket.emit("addNewUser", userId)
  //   socket.on("getOnlineUsers", (res)=>{
  //     dispatch(setOnlineUsers({onlineUsers:res}))
  //   })

  //   return()=>{
  //     socket.off("getOnlineUsers")
  //   }

  // }, [socket])

//send message
  // useEffect(()=>{
  
  //   if(socket === null) return;
  //   const recipientId = currentChat?.members.find((id)=> id !== _id)
  //   socket.emit("sendMessage", {...newMessage, recipientId})
  // }, [newMessage])


  // //receive message
  // useEffect(()=>{
  //   if(socket === null) return;
  //   socket.on("getMessage", (res)=>{
  //     if(currentChat?._id !== res?.chatId){
  //       return;
  //     }else{
  //       console.log('yea')
  //       console.log(res, 'second')
  //       // dispatch(setChatMessages({chat:res}))
          
  //     }
    

  //   });

  //   return ()=>{
  //     socket.off("getMessage")
  //   }

  // }, [socket, currentChat])

  // useEffect(() => {
  //   getUser();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps


// console.log(currentChat?._id, 'chat id')
  if (!user){
    return(
      <Box>
        <div
        style={{
          flex:'row',
          alignItems:'center',
          justifyContent:'center',
          width:'100%',
          height:'100Vh'
        }}
        >
          <Typography>
            Loading......
          </Typography>
        </div>
      </Box>
    )
  };



  return (
    <Box>
      <Navbar isChat={isChat} setIsChat={setIsChat} joinChatHandle={createChats} user={user} receiverId={userId}/>
      <Box
        width='100%'
        padding='2rem 6%'
        display={isNonMobileScreens ? "flex" : "block"}
        gap='2rem'
        justifyContent='center'
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={userId} picturePath={user.picturePath} />
          <Box m='2rem 0' />
          <FriendListWidget userId={userId} />
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <MyPostWidget picturePath={user.picturePath} />
          <Box m='2rem 0' />
          <PostsWidget userId={userId} isProfile />
        </Box>
      </Box>
      {
        isChat? 
        <div style={{
          position: 'fixed',
          bottom: 0, /* Position it at the bottom */
          border:2,
          zIndex:20,
          width:'400px',
          display:'flex',
          flexDirection:'column',
          justifyContent: 'flex-start',
          backgroundColor:'#f0f0f0',
          padding: '10px',
          height: '350px',
        
          right: 8,
          boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.2)'
        }}>
          <ChatApp userId={_id} receiverId={userId} 
        isChat={isChat}
        setIsChat={setIsChat}
          />
        </div> 
      
         :
        
        null
      } 
    </Box>
  );
};

export default ProfilePage;
