import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import moment from 'moment'

import { Box, Divider, IconButton, Typography, useTheme, InputBase,   Button } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState,} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import Comment from "components/Comment"
// import MyCommentWidget from "./CommentWidget";
import {setComment, setComments, setUsers} from "state";


const PostWidget = ({
  postId,
  postUserId,
  name,
  description,
  location,
  createdAt,
  picturePath,
  userPicturePath,
  likes,
  comments,
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const stateComments = useSelector((state) => state.comments);
  const loggedInUserId = useSelector((state) => state?.user?._id);
  const users = useSelector((state)=> state?.users);
  const isLiked = likes? Boolean(likes[loggedInUserId]) : false
  const likeCount = likes ? Object.keys(likes).length : null;
  const [mycomment, setMyComment] = useState('')
  const [commentId, setCommentId] = useState('')

  const [expanded, setExpanded] = useState(false);
  let maxLength =300;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const { palette } = useTheme();
  const main = palette.neutral.main;
  // const primary = palette.primary.main;

  const getAllUsersHandle =async ()=>{
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/users`,{
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
  
    });
    const users = await response.json();
    dispatch(setUsers({ users: users }));
    
  }

  const patchLike = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
  };


  const patchCommentLike = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/comments/${id}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setComment({ post: updatedPost }));
  };


  const getPostsComments = async (parentId) => {
      
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/comments/${parentId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setComments({comments: data }));
  };


  const handleMainComment =  async () => {
    const formData = new FormData();
    formData.append("userId", loggedInUserId);
    formData.append("text", mycomment);
    formData.append("parentId", postId);
    // if (image) {
    //   formData.append("picture", image);
    //   formData.append("picturePath", image.name);
    // }
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/comments`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    const comments = await response.json();
    // //  comments.reverse()
    // console.log(comments, 'res comment')
    // console.log(comments)
    // dispatch(setPost)
    dispatch(setComment({ comments }));
    // setImage(null);
  
  setMyComment("")
  };


  
  const handleComment =  async (data, funct) => {
    const formData = new FormData();
    formData.append("userId", loggedInUserId);
    formData.append("text", data);
    formData.append("parentId", postId);
    // if (image) {
    //   formData.append("picture", image);
    //   formData.append("picturePath", image.name);
    // }
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/comments`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    const comments = await response.json();
    // //  comments.reverse()
    // console.log(comments, 'res comment')
    // console.log(comments)
    // dispatch(setPost)
    dispatch(setComment({ comments }));
    // setImage(null);
  
  setMyComment("")
  };


  useEffect(()=>{
    getAllUsersHandle()
  },[])




  return (
    <WidgetWrapper m='2rem 0' key={postId}>
      <Friend
        friendId={postUserId}
        name={name}
        subtitle={location}
        userPicturePath={userPicturePath}
        postUserId={postUserId}
      />
      {/* <Typography color={main} sx={{ mt: "1rem" }}>
        {description}
      </Typography> */}

{
            expanded?
            (
              <Typography sx={{ color: main, mt: "1rem" }} >
           { description}
            
          
            </Typography>
            )
            :

            (
              <>
                {description?.length > maxLength ? (
                   <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }} >
                    {description?.slice(0, maxLength)}
                    <span onClick={toggleExpand} style={{ color: 'blue', cursor: 'pointer' }}>...</span>
                  </Typography>
                ) : (
                <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }} >{description}</Typography>
                )}
              </>
            )
            
        }
      {picturePath && (
        <img
          width='100%'
          height='auto'
          alt='post'
          style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
          // src={`${process.env.REACT_APP_API_BASE_URL}/assets/${picturePath}`}
          src={picturePath}
        />
      )}
      <FlexBetween>
      <FlexBetween gap='1rem'>
      <Typography color={main} sx={{ mt: "1rem" }} style={{fontSize:"10px"}}>
      posted by: {name} on { moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
      </Typography>
        </FlexBetween>
        {/* <FlexBetween>
        <Typography color={main} sx={{ mt: "1rem" }}>
      at:{ moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
      </Typography>   
        </FlexBetween> */}
      </FlexBetween>
      <FlexBetween mt='0.25rem'>
        <FlexBetween gap='1rem'>
          <FlexBetween gap='0.3rem'>
            <IconButton onClick={patchLike}>
              {isLiked ? (
                <FavoriteOutlined sx={{ color: "#dc2626" }} />
              ) : (
                <FavoriteBorderOutlined sx={{ color: "#000000" }} />
              )}
            </IconButton>
            <Typography sx={{ color: "#000000" }}>{likeCount}</Typography>
          </FlexBetween>

          <FlexBetween gap='0.3rem'>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {setIsComments(!isComments)
                getPostsComments(postId)
              }}
            >
              <ChatBubbleOutlineOutlined />
            </IconButton>
            <Typography sx={{ color: "#000000" }}>{comments?.length}</Typography>
          </FlexBetween>
        </FlexBetween>

        <IconButton>
          <ShareOutlined sx={{ color: "#000000" }} />
        </IconButton>
      </FlexBetween>
      {isComments && (
        <Box mt='0.5rem'>
          {stateComments.map((comment, i) =>{ 
            
            // <Typography>{comment}</Typography>
              
            //  let post = stateComments.find(async(x)=> await x._id === comment)

              return  <Comment comment={comment}
                 patchLike={patchCommentLike}
                //  isLiked={isLiked} likeCount={likeCount} 
                likes={comment.likes}
                // setMyComment={setMyComment}
                // mycomment={mycomment}
                handleComment={handleComment}
                 setIsComments={setIsComments}
                 parentId={comment._id}
                 />
          
          
            
          })}
          <Divider />
          <FlexBetween gap="1.5rem">
        {/* <UserImage image={picturePath} /> */}
        <InputBase
          placeholder="What's on your mind..."
          onChange={(e) => setMyComment(e.target.value)}
          value={mycomment}
          sx={{
            width: "100%",
            backgroundColor: palette.neutral.light,
            borderRadius: "2rem",
            marginTop:'20px',
            padding: "1rem 2rem",
          }}
        />
         <Button
          disabled={!mycomment}
          onClick={handleMainComment}
          sx={{
            color: "#fff",
            backgroundColor: palette.primary.btn,
            borderRadius: "6px",
          }}
          style={{
            color:"#ffffff",
            padding:"3px 5px"
          }}
        >
          Comment
        </Button>
      </FlexBetween>
        </Box>
      )}
    </WidgetWrapper>
  );
};

export default PostWidget;
