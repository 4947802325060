import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  users:[],
  token: null,
  loading:false,
  message:null,
  newChat:{},
  currentChat:[],
  chatMessages:[],
  onlineUsers:[],
  posts: [],
  comments:[]
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {

      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
    setComments: (state, action) => {
      state.comments = action.payload.comments;
    },
    setLoading: (state, action)=>{
      state.loading = !state.loading
    },

    setMessage: (state, action)=>{
      state.message = action.payload.message
    },
    setOnlineUsers: (state, action)=>{
   
      state.onlineUsers = action.payload.onlineUsers
    },

    setCurrentChat:(state, action)=>{
      state.currentChat =action.payload.currentChat
    },
    setNewChat: (state, action)=>{
      state.newChat = action.payload
      // state.chatMessages = [...state.chatMessages, action.payload]
    },
    setChatMessages: (state, action)=>{
      // console.log(action.payload, 'payload')
      // state.chatMessages = [...state.chatMessages, action.payload]
      if(action.payload.chat){
        state.chatMessages = [...state.chatMessages, action.payload.chat]
      }else{
        state.chatMessages=action.payload
      }
    
    },

    setComment:(state, action)=>{
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.comments.parentId){
          post.comments.push(action.payload.comments.text);
        }
        return post;
      });

      state.posts = updatedPosts;
    },

    setUsers:(state, action)=>{
      state.users = action.payload.users
    },

    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("user friends non-existent :(");
      }
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
  },
});

export const { setMode, setLogin, setLogout, setFriends, setPosts, setPost, setComments, 
  setComment, setUsers, setLoading, setMessage, setNewChat,
  setCurrentChat, setChatMessages, setOnlineUsers

} =
  authSlice.actions;
export default authSlice.reducer;
