import { Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";

const AdvertWidget = () => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography color='#000000' variant='h5' fontWeight='600'>
          Sponsored
        </Typography>
        <Typography color={dark} style={{ cursor: "pointer" }}>
          Create Ad
        </Typography>
      </FlexBetween>
      <img
        width='100%'
        height='auto'
        alt='advert'
        src='../assets/conference.jpeg'
        style={{ borderRadius: "0.75rem", margin: "0.75rem 0 0" }}
      />
      <Typography color={medium} m='5px 0'>
        Happening live at Lekki Lagos!
      </Typography>
    </WidgetWrapper>
  );
};

export default AdvertWidget;
