import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box>
      <Box
        width='100%'
        backgroundColor={theme.palette.primary.btn}
        p='1rem 6%'
        textAlign='start'
      >
        <img
          width='50px'
          height='50px'
          alt='post'
          style={{ borderRadius: "100%" }}
          src='../assets/logo.png'
        />
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p='2rem'
        m='2rem auto'
        borderRadius='1.5rem'
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight='500' variant='h4' sx={{ mb: "1.5rem" }}>
          Welcome to Righteous Singles Network, a social platform where
          partnerships and meaningful relationships are forged!
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;
