
export default Notification= ({notification})=>{
    return(
        <div style={{
            width:'300px',
            height:'50px',
            position:'absolute',
            zIndex:30,
            borderRadius:10,
            background:"#000"
          }}>
            <p>Notification</p>
        </div>
    )
} 