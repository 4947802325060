import { PersonAddOutlined, PersonRemoveOutlined, ChatOutlined, ChatBubbleOutlineRounded } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFriends } from "state";
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import { useContext } from "react";
import { ChatContext } from "context/ChatContext";


const Friend = ({ friendId, name, subtitle, userPicturePath, postUserId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user, createChats, currentChat,  onlineUsers, isChat, setIsChat} = useContext(ChatContext)
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const friends = useSelector((state) => state.user.friends);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  // const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const isFriend = friends.find((friend) => friend._id === friendId);

  const patchFriend = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${_id}/${friendId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };


  return (
    <FlexBetween>
      <FlexBetween gap='1rem'>
        <Box
          onClick={() => {
            navigate(`/profile/${friendId}`);
            navigate(0);
          }}
        >
          <UserImage image={userPicturePath} size='55px' />
        </Box>
        <Box>
          <Typography color='#000000' variant='h4' fontWeight='600'>
            {name}
          </Typography>
          <Typography color={medium} fontSize='0.75rem'>
          
            {`${subtitle} ${onlineUsers?.some((user)=> user.userId===friendId)?' | online':""} `}
          </Typography>
        </Box>
      </FlexBetween>
      <FlexBetween>
      {postUserId !== _id && (
        <IconButton
          onClick={() => patchFriend()}
          sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
        >
          {isFriend ? (
            <PersonRemoveOutlined sx={{ color: primaryDark }} />
          ) : (
            <PersonAddOutlined sx={{ color: primaryDark }} />
          )}
        </IconButton>
      
      )}
<Box>
<IconButton
         onClick={() =>{
          createChats(user?._id, friendId)
          navigate(`/profile/${friendId}`);
          // navigate(0);
        
          setIsChat(true)

         } }
         sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
       >
          <ChatOutlined style={{color:'green'}}/>
        </IconButton>
      
          </Box>
          </FlexBetween>
        
    </FlexBetween>
  );
};

export default Friend;
