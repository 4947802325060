import { useCallback } from "react";
import {createContext, useState, useEffect} from "react";

import { fabClasses } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {io} from "socket.io-client"



export const ChatContext = createContext();


export const ChatContextProvider =({children, user})=>{
    const [userChats, setUserChats] = useState(null)
    // const user = useSelector((state)=> state?.user)
    const[isUserChatsLoading, setIsUserChatsLoading] = useState(false)
    const [potentialChats, setPotentialChats] = useState(null)
    const [userChatsError, setUserChatsError] = useState(null)
    const token = useSelector((state) => state.token);
    const [currentChat, setCurrentChat] =useState(null)
    const [socket, setSocket] =useState(null)
    const [messages, setMessages] =useState(null);
    const [isMessagesLoading, setIsMessagesLoading] = useState(false)
    const [messagesError, setMessagesError] = useState(null)
    const [sendTextMessageError, setSendTextMessageError] = useState(null)
    const [newMessage, setNewMessage] = useState(null)
    const [onlineUsers, setOnlineUsers] = useState([])
    const [notifications, setNotifications] = useState([])
    const [isChat, setIsChat] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    // console.log(notifications, 'notify')
    useEffect(()=>{
        const newSocket = io("https://risin-socket-1.onrender.com");
        setSocket(newSocket)
        return ()=>{
          newSocket.disconnect()
        }
      }, [user])

    useEffect(()=>{
        const getUsers =async ()=>{
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/users`, {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
              });
              const data = await response.json()
              if(data.error){
                return console.log("error fetching users", data);
              }

            

              const pChats =data?.filter((z)=>{
                let isChatCreated = false;
                if(user?._id === z._id) return false;

                if(userChats){
                    isChatCreated =userChats?.some((chat)=>{
                        return chat.members[0] === z._id || chat.members[1] === z._id
                    })
                }
                return !isChatCreated
              })

              setPotentialChats(pChats)
        }
        getUsers()


    },[userChats])

    useEffect(()=>{

        if(socket == null) return
        if(user){
            socket.emit("addNewUser", user?._id)
        }
      
        socket.on("getOnlineUsers", (res)=>{
        //   dispatch(setOnlineUsers({onlineUsers:res}))
        setOnlineUsers(res)
        })
    
        return()=>{
          socket.off("getOnlineUsers")
        }
    
      }, [socket])

    useEffect(()=>{
        const getUserChats =async()=>{
            if(user?._id){
                setIsUserChatsLoading(true);
                setUserChatsError(null);

           

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chats/${user?._id}`, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` },
                  });
                  const data = await response.json()
                  if(data.error){
                  
                    return setUserChatsError(data)
                  }

                  console.log(data, 'chat data')
                    setUserChats(data)
            }
        }

        getUserChats()
    },[user])

 

      useEffect(()=>{

        if(socket == null) return
        socket.emit("addNewUser", user?.id)
        socket.on("getOnlineUsers", (res)=>{
          setOnlineUsers(res)
        })
    
        return()=>{
          socket.off("getOnlineUsers")
        }
    
      }, [socket])


      //send message

      useEffect(()=>{
  
        if(socket === null) return;

        const recipientId = currentChat?.members.find((id)=> id !== user?._id)

        // console.log('reciepient', recipientId)

        socket.emit("sendMessage", {...newMessage, recipientId})
      }, [newMessage])

      //receive message and notifications

      
  //receive message
  useEffect(()=>{
    if(socket === null) return;
    socket.on("getMessage", (res)=>{
      if(currentChat?._id !== res?.chatId){
        return;
      }else{
        // dispatch(setChatMessages({chat:res}))
        setMessages((prev)=>[...prev, res])
          
      }

      socket.on("getNotification", (res)=>{
        const isChat =currentChat?.memebers?.some((id)=> id === res?.senderId)
        if(isChat){
          setNotifications((prev)=> [{...res, isRead:true}, ...prev])
        }else{
          setNotifications((prev)=> [res, ...prev])
        }
      })
     
    

    });

    return ()=>{
      socket.off("getMessage")
      socket.off("getNotification")
    }

  }, [socket, currentChat])


      useEffect(()=>{
       const getMessage =async ()=>{
        setIsMessagesLoading(true);
        setMessagesError(null);
        const currentId = currentChat?._id
        if(!currentId) return null
    
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/messages/${currentId}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}`, "Content-type":"application/json"},
        });
        const data = await response.json()

        if(data.error){

            return setMessagesError(data)
        }

        setMessages(data);

       }

      getMessage()
    
      }, [currentChat])

      const sendTextMessage =useCallback(async(textMessage, sender, currentChatId, setTextMessage)=>{
        if(!textMessage) return console.log("You must type something...")
        const body = {
            chatId:currentChatId,
            senderId:sender,
            text:textMessage
          }
  
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/messages`, {
            method: "POST",
            headers: { Authorization: `Bearer ${token}`, "Content-type":"application/json"},
            body: JSON.stringify(body)
          });

          const data = await response.json()
          if(data.error){
            return setSendTextMessageError(response)
        }

        setNewMessage(data);
        setMessages((prev)=>[...prev, data])
        setTextMessage('')

      },[])

      const updateCurrentChat = useCallback((chat)=>{
        setCurrentChat(chat);
      }, [])


     const createChats =useCallback(async(firstId, secondId)=>{
        const body = {
            firstId: firstId,
            secondId:secondId
        }
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chats`, {
              method: "POST",
              headers: { Authorization: `Bearer ${token}`, "Content-type":"application/json"},
              body: JSON.stringify(body)
            });

            const data = await response.json()
            if(data.error){
                return console.log("Error creating chat", data)
            }

            // setUserChats((prev)=>[...prev, data])
            setCurrentChat(data)
     },[]) 


      return(
        <ChatContext.Provider
        value={{
            userChats,
            isUserChatsLoading,
            userChatsError,
            newMessage,
            setNewMessage,
            user,
            createChats,
            updateCurrentChat,
            messages,
            isMessagesLoading,
            messagesError,
            currentChat,
            setSocket,
            isChat,
            setIsChat,
            sendTextMessage,
            onlineUsers,
            isOpen,
            setIsOpen,
            notifications
        }}
        >
            {children}

        </ChatContext.Provider>

      )
}