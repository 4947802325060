import { Box, useMediaQuery } from "@mui/material";
import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import ChatApp from "scenes/Chat";
import { ChatContext } from "context/ChatContext";
import Notification from "components/Notification";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);
  const [isChat, setIsChat] = useState(false)
  const {isOpen, notifications} = useContext(ChatContext)

  // console.log(notifications, 'open noti')

  return (
    <Box>
      <Navbar isChat={isChat} setIsChat={setIsChat} />
      <Box
        width='100%'
        padding='2rem 6%'
        display={isNonMobileScreens ? "flex" : "block"}
        gap='0.5rem'
        justifyContent='space-between'
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={_id} picturePath={picturePath} />
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <MyPostWidget picturePath={picturePath} />
          <PostsWidget userId={_id} />
         
        </Box>
        <Box style={{position:'relative'}}>
       {
        notifications?.length > 0 && isOpen ?
        notifications?.maps((notification, index)=>(
          <Notification notification={notification} key={index}/>
        ))
        :
        
        null
       }
        
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis='26%'>
            <AdvertWidget />
            <Box m='2rem 0' />
            <FriendListWidget userId={_id} />
          </Box>
        )}
      </Box>
      {
        isChat? 
        <div style={{
          position: 'fixed',
          bottom: 0, /* Position it at the bottom */
          border:2,
          zIndex:20,
          width:'400px',
          display:'flex',
          flexDirection:'column',
          justifyContent: 'flex-end',
          backgroundColor:'#f0f0f0',
          padding: '20px',
          height: '350px',
          right: 8,
          boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.2)'
        }}>
          <ChatApp userId={_id}/>
        </div> 
      
         :
        
        null
      } 
    
    </Box>
  );
};

export default HomePage;
